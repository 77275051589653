
// import 'babel-polyfill';

const $ = document.querySelector.bind(document);
const $$ = (...args) => [...document.querySelectorAll(...args)];

// TOUT DOIT ÊTRE PLUGABLE / MODULABLE avec API
// ex: addFilter(...), onRefresh(...), onCommand(...)

// TODO:
// arrow up: latest commands -> filter start with
// reorganize code in package, self contained
// import { $, $$ } from ..
// refresh queue // updateLgos queue
// change server/logs fast => handle correctly
// server status (ping, players, etc)
// on refresh -> keep what we are seeing + update only needed
// filter -> chat, commands, join/quit, info/warn/severe
// download log file

const $logger = $('.logger');
const $selector = $('.log-selector');
const $console = $('.console');
const $refresh = $('.refresh');

const logger = (text = null) => {
  if (typeof text === 'string' || typeof text === 'number') {
    $logger.style.display = '';
    $logger.textContent = text;
  } else {
    $logger.style.display = 'none';
  }
};

const lines = 1000;
let multiplier = 1;

const filters = {
  last: {
    parse: t => t.slice(-lines * multiplier),
    scroll: () => { $console.scrollTop = $console.scrollHeight; },
    hasMore: t => t.length - (lines * multiplier) > 0,
  },
  first: {
    parse: t => t.slice(0, lines * multiplier),
    scroll: () => { $console.scrollTop = 0; },
    hasMore: t => t.length - (lines * multiplier) > 0,
  },
  all: {
    parse: t => t,
    scroll: () => { $console.scrollTop = 0; },
    hasMore: () => false,
  },
};

let refreshInterval = null;
let updateInterval = () => {}; // D:
let selectedFilter = 'last';
$console.dataset.filter = selectedFilter;
let canSendCommand = false;

let server = null;
let logs = null;

const clearConsole = (loader = false) => {
  $console.classList.remove('has-data', 'is-loading', 'has-more');
  $console.innerHTML = loader ? '<div class="loader"></div>' : '';
};

const updateLogs = (newlogs = null, reset = true, callback = null) => {
  if (newlogs) {
    logs = newlogs;
  }

  const filter = filters[selectedFilter];
  if (logs && filter) {
    let wasOnBottom = false;

    if (reset) {
      clearConsole(true);
    } else if ($console.offsetHeight + $console.scrollTop === $console.scrollHeight) {
      wasOnBottom = true;
    }

    $console.classList.add('is-loading');

    setTimeout(() => {
      $console.innerHTML = filter.parse(logs).join('');

      if (filter.hasMore(logs)) {
        $console.classList.add('has-more');
      } else {
        $console.classList.remove('has-more');
      }

      // force calculate $console.offsetHeight to be sure content is loaded
      if (typeof $console.offsetHeight !== 'string') {
        $console.classList.add('has-data');
        $console.classList.remove('is-loading');

        if (reset) {
          filter.scroll();
        } else if (wasOnBottom) {
          $console.scrollTop = $console.scrollHeight;
        }

        if (callback) {
          callback();
        }
      }
    }, 15);
  }
};

const refresh = (clear = true) => {
  if ($selector.value && server) {
    // clear console
    if (clear) {
      clearConsole();
    }

    // reset
    if ($selector.value !== 'latest.log') {
      updateInterval(0);
      $('.refresh-input-default').checked = true;
      $refresh.style.display = 'none';
    } else {
      $refresh.style.display = '';
      // restart so manually refresh or cmd wont update again
      updateInterval();
    }

    // and fetch
    logger('fetching logs..');
    window.fetch('/api/console/' + encodeURIComponent(server) + '/' + encodeURIComponent($selector.value))
      .then((data) => {
        logger('rendering..');
        console.time('parsing');
        return data.json();
      })
      .then((data) => {
        console.timeEnd('parsing');
        console.time('render');
        updateLogs(data, clear);
      })
      .then(() => {
        console.timeEnd('render');
        logger();
      })
      .catch((err) => {
        logger('error');
        console.warn(err);
      });
  } else {
    clearConsole();
  }
};

let intervalValue = 0;
updateInterval = (value = intervalValue) => {
  clearInterval(refreshInterval);
  intervalValue = value;
  if (value > 0) {
    refreshInterval = setInterval(() => {
      refresh(false);
    }, (value * 1000) + 50);
  }
};

$$('.select-server').forEach(node => node.addEventListener('click', function (e) {
  e.preventDefault();

  server = null;
  multiplier = 1;
  clearConsole();
  updateInterval(0);
  $('.refresh-input-default').checked = true;
  $refresh.style.display = 'none';

  $$('.select-server').forEach((btn) => {
    btn.classList.add('btn-primary');
    btn.classList.remove('btn-default');
  });

  this.classList.remove('btn-primary');
  this.classList.add('btn-default');

  if (this.dataset.server) {
    logger('fetching files..');
    // clear select
    $selector.innerHTML = '<option>Logs are loading..</option>';

    window.fetch('/api/logs/' + this.dataset.server)
      .then(files => files.json())
      .then((files) => {
        // set server
        server = this.dataset.server;

        // clear select
        [...$selector.querySelectorAll('option')].forEach(n => n.remove());

        // add placeholder
        const placeholder = document.createElement('option');
        placeholder.value = '';
        placeholder.textContent = 'Now select a log file';
        $selector.appendChild(placeholder);

        let hasLatest = false;

        // add options
        files.logs.reverse().forEach((file) => {
          const option = document.createElement('option');
          option.value = file.name;
          option.textContent = `${file.name} -- ${Math.round(file.size / 1000)}ko`;
          hasLatest = hasLatest || file.name === 'latest.log';
          $selector.appendChild(option);
        });

        canSendCommand = files.rcon;

        logger();

        // select latest.log
        if (hasLatest) {
          $selector.value = 'latest.log';
          $selector.dispatchEvent(new window.Event('change'));
        }
      })
      .catch((err) => {
        logger('error');
        console.warn(err);
      });
  }
}));

$selector.addEventListener('change', function () {
  multiplier = 1;
  if (canSendCommand && this.value === 'latest.log') {
    $('.logs').classList.add('has-rcon');
  } else {
    $('.logs').classList.remove('has-rcon');
  }
  refresh();
});

$('.btn-refresh').addEventListener('click', (e) => {
  e.preventDefault();
  multiplier = 1;
  refresh();
});

$$('.option').forEach(node => node.addEventListener('click', e => e.preventDefault()));

$('.to-top').addEventListener('click', () => { $console.scrollTop = 0; });
$('.to-bottom').addEventListener('click', () => { $console.scrollTop = $console.scrollHeight; });

$$('.set-filter').forEach(node => node.addEventListener('click', function () {
  $$('.set-filter').forEach(n => n.classList.remove('disabled'));

  if (this.dataset.filter && filters[this.dataset.filter]) {
    selectedFilter = this.dataset.filter;
    $console.dataset.filter = selectedFilter;
    this.classList.add('disabled');
    multiplier = 1;
    updateLogs();
  }
}));

$$('.refresh-input').forEach(node => node.addEventListener('change', function () {
  updateInterval(parseInt(this.value, 10));
}));

$$('.set-maximize').forEach(node => node.addEventListener('click', function () {
  if (this.dataset.value === 'true') {
    document.body.classList.add('maximized');
  } else {
    document.body.classList.remove('maximized');
  }
}));

document.addEventListener('keyup', (e) => {
  if (!(e.target instanceof window.HTMLInputElement) || e.target.type !== 'text') {
    if (e.keyCode === 13) { // enter
      document.body.classList.add('maximized');
    } else if (e.keyCode === 27) { // esc
      document.body.classList.remove('maximized');
    } else if (e.keyCode === 82) { // R
      refresh(false);
    }
  }
});

$console.addEventListener('scroll', (e) => {
  if ($console.scrollTop === 0) {
    // top
    if (selectedFilter === 'last') {
      const first = $console.querySelector(':first-child');
      if (first) {
        const index = parseInt(first.dataset.index, 10);
        if (index > 1) {
          multiplier += 1;
          const oldline = $console.querySelector('[data-index="' + index + '"]');
          const top = oldline && oldline.getBoundingClientRect().top;
          updateLogs(null, false, () => {
            if (index > 0) {
              const line = $console.querySelector('[data-index="' + index + '"]');
              if (line) {
                if (typeof top === 'number') {
                  const diff = top - $console.getBoundingClientRect().top;
                  line.scrollIntoView();
                  $console.scrollTop -= diff;
                } else {
                  line.scrollIntoView();
                  // padding top of console
                  $console.scrollTop -= 8;
                  console.log(line, index);
                }
              }
            }
          });
        }
      }
    }
  } else if ($console.offsetHeight + $console.scrollTop === $console.scrollHeight) {
    // bottom
    if (selectedFilter === 'first') {
      multiplier += 1;
      const last = $console.querySelector(':first-child');
      updateLogs(null, false);
      console.log('last', last);
      // last.scrollIntoView();
    }
  }
  console.log('scroll (x', multiplier, ')');
  e.preventDefault();
});

const commands = [];
let index = 0;

$('.command').addEventListener('keydown', function (e) {
  if (e.keyCode === 38 || e.keyCode === 40) { // up || down
    e.preventDefault();

    index += e.keyCode === 38 ? 1 : -1;
    index = Math.min(Math.max(index, 0), commands.length);

    if (index > 0) {
      this.value = commands[commands.length - index];
    } else {
      this.value = '';
    }
  }
});

$('.command').addEventListener('keyup', function (e) {
  if (e.keyCode === 13 || e.keyCode === 27) { // enter || esc
    e.preventDefault();
    index = 0;
    commands.push(this.value);
    if (e.keyCode === 13 && this.value && server) {
      window.fetch('/api/command/' + encodeURIComponent(server) + '/' + encodeURIComponent('console ' + this.value));
      setTimeout(() => {
        refresh(false);
      }, 250);
        // .then(x => x.text())
        // .then(console.log.bind(console));
    }
    this.value = '';
  }
});

$('.restart').addEventListener('click', function (e) {
  e.preventDefault();
  window.fetch('/api/restart');
});
